import React from 'react'
import './below_bg.css'
import { Chip, Container, Grid, Typography } from '@mui/material'
export default function Below_bg() {
  
  
  const arr=[{head:'Performance Marketing',desc:'Results-oriented, performance marketing focuses on quantifiable objectives like clicks, prospects, or sales. It enables advertisers to optimize campaigns in real time by providing precise targeting, flexibility, and scalability. It is transparent and affordable, allowing for the effective use of resources and encouraging accountability between publishers and advertisers to ensure win-win collaborations.'},
  {head:'Growth Marketing',desc:'Growth marketing uses customer-centric tactics and data-driven experimentation to prioritize quick, sustainable growth. From customer acquisition to retention, it places a strong emphasis on ongoing optimization. Agile and flexible, it produces long-term value and cultivates enduring relationships with consumers by leveraging insights to untapped opportunities.'},
  {head:'Brand Marketing',desc:'Brand marketing cultivates emotional connections and brand loyalty by conveying a compelling narrative and unique identity. It focuses on creating a lasting impression of the brand and gaining the confidence and trust of audience. It promotes affinity, advocacy, and eventually revenue by differentiating the brand, connecting with consumers, and using narrative, authenticity, and consistent messaging.'},
  {head:'Programmatic',desc:'Programmatic services use data and algorithms to precisely target audiences by automating the buying and placement of ads. They provide efficiency, scalability, and real-time optimization across several channels. They simplify campaign management, optimize ROI, and enable dynamic, data-driven advertising tactics with their transparent, granular targeting options.'},
]
  // "Performance Marketing","Growth Marketing ","Branding Marketing","Programmatic"]

  
    return (
<Grid container justifyContent={'center'}>

    <Container style={{margin:'4vh 0px',textAlign:'-webkit-center'}}>
        <Grid  container style={{margin:'10px 0px'}}>
            <Grid item alignContent='center' sm={4} xs={12}>
<Typography className='bb_txt1'>
WHAT WE DO
</Typography>
<Typography className='bb_txt2'>
EXPERTS
</Typography>
<Typography className='bb_txt3'>
Let's talk performance marketing!
</Typography>
<img  style={{width:'30%'}} src={require('../../images/divider.png')} />
            </Grid>
            {arr.map((val,idx)=>
           <Grid key={idx}style={{padding:10}} item sm={4} xs={12}>
    
           <div  data-aos="flip-up"  data-aos-duration="1500" className='bb_services'>
           
           <Typography className='bb_services_head' >
 {val.head}
           </Typography>
           <Typography className='bb_services_desc' >
           {val.desc}           </Typography>
           
           </div>
           
           
                </Grid> )}
        </Grid>

    </Container>

    <Grid container style={{paddingTop:'5vh'}} sm={12} xs={12}>
       
        <div className='bg_quote'>

<Grid item sm={12} xs={12}>
<Typography className='bg_quote_txt1'>
BEST AFFILIATES EVER 
</Typography>
</Grid>
<Grid item sm={12} xs={12}>
<Typography className='bg_quote_txt2'>
Time to grow your affiliate network!
</Typography>
</Grid>
<Grid item textAlign={'center'} sm={12} xs={12}>
<Chip onClick={()=>window.location.href='/contact'} label='Get quote' style={{cursor:'pointer',background:'var(--color3)',color:'white',padding:'20px 10px',textTransform:'uppercase',fontWeight:'700',marginTop:'1vh'}} />
</Grid>
        </div>
    </Grid>
    </Grid>
  )
}
